import React from 'react'
import config from '../../../config'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content'>
          <div className='col-md-10 col-sm-8 col-xs-10 offset-sm-1'>
          <div className="row">
          <div className="col-md-4">
            <div className="section1">
            {/*<div className="row info">*/}
              {/*<div className="col-md-12">*/}
                <p>
                  {config.copyright}
                </p>
              {/*</div>*/}
            </div>
          </div>
            <div className="col-md-4">
            </div>

          <div className="col-md-4">
            <div className="info">
              {/*<div className="col-md-12">*/}

                <div className="phone">
                  <div className="whatsapp">
                  <a href="viber://add?number=+380989258088" className="viberlink"></a>
                  <a href="whatsapp://send?phone=+380989258088&amp;text=Доброго дня" className="whatsupplink"></a>
                  </div>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+380989258088"> +38 098 9258088</a>
                </div>
                <div className="email">
                  <i className="fa big-icon fa-envelope"></i>
                  <a href="mailto:krantechnik.ua@gmail.com">  krantechnik.ua@gmail.com</a></div>
              </div>

          </div>
          </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
