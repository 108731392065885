import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import 'font-awesome/css/font-awesome.min.css'
import SearchBox from '../SearchBox'

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <nav className='navbar is-fixed-top' aria-label='main navigation'>

        <div className='navbar-brand'>
          <Link to='/'>
            <i className="fa fa-home fa-lg"></i>
          </Link>
          <button
            className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
            data-target='navMenu'
            onClick={toggleNavbar}
          >
            <span/>
            <span/>
            <span/>
          </button>
        </div>

        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id='navMenu'>
          <div className='navbar-end'>


            <div className="navbar-item">
              <div>
                {/*<a href="tel:+78124148060"><i className="fa big-icon s45 fa-phone"></i></a>*/}
                <i className="fa fa-phone"></i>
                <a href="tel:+380989258088"> +38 098 9258088</a>
              </div>
            </div>
            <div className="navbar-item">
              <a href="viber://add?number=+380989258088" className="viberlink"></a>
              <a href="whatsapp://send?phone=+380989258088&amp;text=Hello" className="whatsupplink"></a>
            </div>
            {/*</div>*/}
            {/*<SearchBox searchIndex={data.siteSearchIndex.index} />*/}
            {/*            <Link className='navbar-item' to='/about'>
                            О нас
            </Link>*/}
            <div className='navbar-item'>
              <Link
                className='button is-primary is-outlined'
                to='/about'>
                Про нас
              </Link>
            </div>

            {/*
            <Link className='navbar-item' to='/pricing'>
                            Pricing
            </Link>
            <Link className='navbar-item' to='/blog'>
                            Blog
            </Link>
*/}
            <div className='navbar-item'>
              <div className='field is-grouped'>
                <p className='control'>
                  <Link
                    className='button is-primary is-outlined'
                    to='/contact'>
                    Контакти
                  </Link>
                </p>
              </div>
            </div>
            {/*<div className='navbar-item'>*/}
              {/*<div className='button is-primary is-outlined flagukr'></div>*/}
              {/*<a className='button is-primary is-outlined flagrus' href="http://rus.krantechnik.com.ua"></a>*/}
            {/*</div>*/}
          </div>
        </div>
      </nav>

    )}
  />
)

export default NavBar
